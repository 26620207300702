import React, { useEffect, useState, useRef } from "react";
import { Link, useParams, useNavigate, useOutletContext } from "react-router-dom";
import { GetApiHandlerWithoutParams, PostApiHandler } from "../utils/Api";
import LocationDateFilterModal from "../Components/Modal/LocationDateFilterModal";
import p1 from "../assets/images/product-image/1.jpg";
import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import { Circles } from 'react-loader-spinner';
import CustomToast from "../Components/CustomToast";
import IsToken from "../utils/Handeler/IsToken";

export default function SingleProduct() {
  const { id } = useParams();
  const { fetchCartItems, locationAndDates, setLocationAndDates } = useOutletContext();
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(true);
  const [showLocationDateModal, setShowLocationDateModal] = useState(false);
  const [similarProducts, setSimilarProducts] = useState([]);
  const navigate = useNavigate();

  const swiperRefs = useRef(null);

  const openLocationDateModal = () => setShowLocationDateModal(true);
  const closeLocationDateModal = () => setShowLocationDateModal(false);

  useEffect(() => {
    if (id) {
      fetchProductData(id);
  
      // Only open the modal if the user is logged in and location/dates are missing
      if (IsToken() && (!locationAndDates || !locationAndDates.locationId || !locationAndDates.startDate || !locationAndDates.endDate)) {
        openLocationDateModal();
      }
    }
  }, [id, locationAndDates]);

  const fetchProductData = async (inventoryId) => {
    setLoading(true);
    try {
      const productEndPoint = `inventories/${inventoryId}`;
      const productResult = await GetApiHandlerWithoutParams(productEndPoint, "");

      if (productResult?.success && productResult.inventory?.product) {
        const fetchedProduct = {
          id: productResult.inventory.product.id,
          name: productResult.inventory.product.product_name,
          price: productResult.inventory.product.rental_price,
          image: productResult.inventory.product.image_1 || p1,
          location: productResult.inventory.location.name,
          locationId: productResult.inventory.location.id,
          description: productResult.inventory.product.description,
          short_description: productResult.inventory.product.short_description,
          quantityAvailable: productResult.available_quantity,
        };
        setProduct(fetchedProduct);
        setSimilarProducts(productResult.similar_products || []); // Set similar products
      }
    } catch (error) {
      console.error("Failed to fetch product details:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddToCart = async () => {
    // If the user is not logged in, redirect them to the login page
    if (!IsToken()) {
      CustomToast("Please log in to add items to the cart", "warning");
      navigate("/login", { state: { from: `/product/${id}` } });
      return;
    }
  
    // Check if the location and dates are selected for logged-in users
    if (!locationAndDates || !locationAndDates.locationId || !locationAndDates.startDate || !locationAndDates.endDate) {
      CustomToast("Please select a location and date first", "warning");
      openLocationDateModal(); // Reopen the modal if dates are not selected
      return;
    }
  
    // Check if the selected quantity exceeds the available quantity
    if (quantity > product.quantityAvailable) {
      CustomToast(`Cannot add more than the available quantity: ${product.quantityAvailable}`, "warning");
      return;
    }
  
    try {
      const body = {
        product_id: product.id,
        quantity: quantity,
        location_id: locationAndDates.locationId,
        rentalstartdate: locationAndDates.startDate,
        rentalenddate: locationAndDates.endDate,
      };
      const result = await PostApiHandler(body, "carts");
  
      if (result.success) {
        await fetchCartItems(); // Update cart items after successful addition
      } else {
        CustomToast(`Failed to add product to cart: ${result.message}`, "error");
      }
    } catch (error) {
      CustomToast(`Failed to add product to cart: ${error.message}`, "error");
    }
  };

  const handleQuantityChange = (newQuantity) => {
    if (newQuantity > product.quantityAvailable) {
      CustomToast(`Only ${product.quantityAvailable} items are available.`, "warning");
      setQuantity(product.quantityAvailable);
    } else if (newQuantity < 1) {
      setQuantity(1);
    } else {
      setQuantity(newQuantity);
    }
  };

  const incrementQuantity = () => handleQuantityChange(quantity + 1);
  const decrementQuantity = () => handleQuantityChange(quantity - 1);

  const handleLocationDateSubmit = (formData) => {
    setLocationAndDates(formData);
    localStorage.setItem("locationDateFilter", JSON.stringify(formData));
    closeLocationDateModal();
  };

  useEffect(() => {
    if (!loading && product) {
      swiperRefs.current = new Swiper(".product-image-slider", {
        slidesPerView: 4,
        spaceBetween: 0,
        loop: true,
        autoplay: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    }

    return () => {
      if (swiperRefs.current) {
        swiperRefs.current.destroy();
      }
    };
  }, [loading, product]);

  useEffect(() => {
    if (similarProducts.length > 0) {
      swiperRefs.current = new Swiper(".new-product-slider", {
        slidesPerView: 4,  // Display 4 slides at once
        spaceBetween: 20,  // Add space between slides
        loop: true,        // Infinite loop
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next1",
          prevEl: ".swiper-button-prev1",
        },
      });
    }

    return () => {
      if (swiperRefs.current) {
        swiperRefs.current.destroy();
      }
    };
  }, [similarProducts]);

  if (loading) {
    return (
      <div className="loading-container">
        <Circles
          height="100"
          width="100"
          color="#ff7004"
          ariaLabel="loading"
          wrapperStyle={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}
        />
      </div>
    );
  }

  return (
    <>
      {/* Product Details Area Start */}
      <div className="product-details-area pt-100px pb-100px">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-sm-12 col-xs-12 mb-lm-30px mb-md-30px mb-sm-30px">
              <div className="swiper-container zoom-top">
                <div className="swiper-wrapper">
                  <div className="swiper-slide zoom-image-hover">
                    <img
                      className="img-responsive m-auto"
                      src={product?.image || p1}
                      alt={product?.name || "Product Image"}
                    />
                  </div>
                </div>
              </div>
              <div className="product-image-slider swiper-container zoom-thumbs slider-nav-style-1 small-nav mt-15px mb-15px">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <img
                      className="img-responsive m-auto"
                      src={product?.image || p1}
                      alt={product?.name || "Product Image"}
                    />
                  </div>
                </div>

                <div className="swiper-buttons">
                  <div className="swiper-button-next"></div>
                  <div className="swiper-button-prev"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-sm-12 col-xs-12" data-aos="fade-up" data-aos-delay="200">
              <div className="product-details-content quickview-content">
                <h2>{product?.name || "Product Name"}</h2>
                <p className="reference">Available Quantity: <span>{product?.quantityAvailable}</span></p>
                <div className="pricing-meta">
                  <ul>
                    {IsToken() && <li className="old-price not-cut">
                      ₹{product?.price || 0}
                    </li>}
                  </ul>
                </div>
                <p className="quickview-para">
                  {product?.short_description?.replace(/<\/?[^>]+(>|$)/g, "") || ""}
                </p>
                <div className="pro-details-quality">
                  <div className="cart-plus-minus">
                    <button className="dec qtybutton" onClick={decrementQuantity}>-</button>
                    <input
                      className="cart-plus-minus-box"
                      type="text"
                      name="qtybutton"
                      value={quantity}
                      onChange={(e) => handleQuantityChange(parseInt(e.target.value))}
                      min="1"
                    />
                    <button className="inc qtybutton" onClick={incrementQuantity}>+</button>
                  </div>
                  <div className="pro-details-cart">
                    <button className="add-cart btn btn-primary btn-hover-primary ml-4" onClick={handleAddToCart}>
                      Add To Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Product Details Description Area Start */}
      <div className="description-review-area pb-100px" data-aos="fade-up" data-aos-delay="200">
        <div className="container">
          <div className="description-review-wrapper">
            <div className="description-review-topbar nav">
              <Link data-bs-toggle="tab" to="#des-details1">
                Description
              </Link>
              <Link className="active" data-bs-toggle="tab" to="#des-details2">
                Product Details
              </Link>
            </div>
            <div className="tab-content description-review-bottom">
              <div id="des-details2" className="tab-pane active">
                <div className="product-anotherinfo-wrapper">
                  <ul>
                    <li>
                      <span>Location</span> {product?.location || "Unknown"}
                    </li>
                  </ul>
                </div>
              </div>
              <div id="des-details1" className="tab-pane">
                <div className="product-description-wrapper">
                  <p>{product?.description?.replace(/<\/?[^>]+(>|$)/g, "") || "No description available."}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Product Details Description Area End */}


      {/* Related Products */}
      <div className="section pb-100px" data-aos="fade-up" data-aos-delay="200">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title text-start mb-11">
                <h2 className="title">You Might Also Like</h2>
              </div>
            </div>
          </div>
          <div className="new-product-slider swiper-container slider-nav-style-1" data-aos="fade-up" data-aos-delay="200">
            <div className="new-product-wrapper swiper-wrapper">
            {similarProducts.length > 0 ? (
  similarProducts.map((product) => (
    <div key={product.product.id} className="new-product-item swiper-slide">
      <div className="product">
        <div className="thumb">
          {product.inventory ? (
            <Link to={`/product/${product.inventory.id}`} className="image">
              <img src={product.product.image_1 || p1} alt={product.product.product_name} />
              <img className="hover-image" src={product.product.image_2 || p1} alt={product.product.product_name} />
            </Link>
          ) : (
            <div className="image-placeholder">
              <img src={p1} alt="Placeholder Image" />
            </div>
          )}
        </div>
        <div className="content">
          <h5 className="title">
            {product.inventory ? (
              <Link to={`/product/${product.inventory.id}`}>{product.product.product_name}</Link>
            ) : (
              product.product.product_name
            )}
          </h5>
          <span className="price">
            <span className="new">₹{product.product.rental_price}</span>
          </span>
        </div>
      </div>
    </div>
  ))
) : (
  <p>No similar products found.</p>
)}

            </div>
            <div className="swiper-buttons">
              <div className="swiper-button-next1"></div>
              <div className="swiper-button-prev1"></div>
            </div>
          </div>
        </div>
      </div>

      {/* Location Date Filter Modal */}
      {showLocationDateModal && (
        <LocationDateFilterModal
          show={showLocationDateModal}
          onClose={closeLocationDateModal}
          onSubmit={handleLocationDateSubmit}
        />
      )}
    </>
  );
}
