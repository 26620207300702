import { useState } from "react";
import { RegisterPostApiHandler } from "../utils/Api";
import { useNavigate } from "react-router-dom";

export default function Register() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [companyname, setcompanyname] = useState("");
  const [companyaddress, setcompanyaddress] = useState("");
  const [city, setcity] = useState("");
  const [pincode, setpincode] = useState("");
  const [gstnumber, setgstnumber] = useState("");
  const [sizeofeventcompany, setsizeofeventcompany] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal state
  const [state, setstate] = useState("");

  const [errorMessage, setErrorMessage] = useState({
    email: "",
    name: "",
    phonenumber: "",
    companyname: "",
    companyaddress: "",
    city: "",
    pincode: "",
    gstnumber: "",
    sizeofeventcompany: "",
    state: "",
    register: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigateTo = useNavigate();

  // Handle changes for all inputs
  const handleChange = (e, setter, field) => {
    setter(e.target.value);
    setErrorMessage((prev) => ({ ...prev, [field]: "" }));
  };

  const handleRegistrationSuccess = () => {
    setIsModalVisible(true); // Show success modal after registration
  };

  const handleModalClose = () => {
    setIsModalVisible(false); // Close modal
    navigateTo("/login"); // Redirect to login or any other action
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Validate required fields
    if (
      !phonenumber ||
      !email ||
      !name ||
      !companyname ||
      !companyaddress ||
      !city ||
      !pincode ||
      !state
    ) {
      setErrorMessage((prev) => ({
        ...prev,
        phonenumber: phonenumber ? "" : "This phone field is required.",
        email: email ? "" : "This email field is required.",
        name: name ? "" : "This name field is required.",
        companyname: companyname ? "" : "Company name is required.",
        companyaddress: companyaddress ? "" : "Company address is required.",
        city: city ? "" : "City is required.",
        pincode: pincode ? "" : "Pincode is required.",
        state: state ? "" : "State is required.",
      }));
      setIsSubmitting(false);
      return;
    }

    setErrorMessage({});

    const formData = new FormData();
    formData.append("name", name);
    formData.append("mobile", phonenumber);
    formData.append("email", email);
    formData.append("usertype", "event manager");
    formData.append("company_name", companyname);
    formData.append("company_address", companyaddress);
    formData.append("city", city);
    formData.append("pincode", pincode);
    formData.append("state", state);
    formData.append("gst_number", gstnumber || ""); // Optional

    try {
      const response = await RegisterPostApiHandler(formData, "register");
      if (response.success) {
        handleRegistrationSuccess(); // Show the success modal
      } else {
        setErrorMessage((prev) => ({ ...prev, register: response.message }));
      }
    } catch (error) {
      setErrorMessage((prev) => ({
        ...prev,
        register: "Something went wrong, please try again later.",
      }));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="login-register-area pt-100px pb-100px">
        <div className="container">
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6 col-md-12 ml-auto mr-auto">
              <div className="login-register-wrapper">
                <div className="login-register-tab-list nav">
                  <a className="active" data-bs-toggle="tab" href="#lg1">
                    <h4>Vendor Registration Form</h4>
                  </a>
                </div>
                <div className="tab-content">
                  <div id="lg1" className="tab-pane active">
                    <div className="login-form-container">
                      <div className="login-register-form">
                        <form onSubmit={handleFormSubmit}>
                          <input
                            type="text"
                            placeholder="Name"
                            onChange={(e) => handleChange(e, setName, "name")}
                            value={name}
                            disabled={isSubmitting}
                          />
                          {errorMessage.name && (
                            <span className="error">{errorMessage.name}</span>
                          )}

                          <input
                            type="text"
                            placeholder="Mobile Number"
                            onChange={(e) =>
                              handleChange(e, setPhonenumber, "phonenumber")
                            }
                            value={phonenumber}
                            disabled={isSubmitting}
                          />
                          {errorMessage.phonenumber && (
                            <span className="error">
                              {errorMessage.phonenumber}
                            </span>
                          )}

                          <input
                            type="email"
                            placeholder="Email"
                            onChange={(e) =>
                              handleChange(e, setEmail, "email")
                            }
                            value={email}
                            disabled={isSubmitting}
                          />
                          {errorMessage.email && (
                            <span className="error">{errorMessage.email}</span>
                          )}

                          <input
                            type="text"
                            placeholder="Company Name"
                            onChange={(e) =>
                              handleChange(e, setcompanyname, "companyname")
                            }
                            value={companyname}
                            disabled={isSubmitting}
                          />
                          {errorMessage.companyname && (
                            <span className="error">
                              {errorMessage.companyname}
                            </span>
                          )}

                          <input
                            type="text"
                            placeholder="Company Address"
                            onChange={(e) =>
                              handleChange(
                                e,
                                setcompanyaddress,
                                "companyaddress"
                              )
                            }
                            value={companyaddress}
                            disabled={isSubmitting}
                          />
                          {errorMessage.companyaddress && (
                            <span className="error">
                              {errorMessage.companyaddress}
                            </span>
                          )}

                          <input
                            type="text"
                            placeholder="City"
                            onChange={(e) => handleChange(e, setcity, "city")}
                            value={city}
                            disabled={isSubmitting}
                          />
                          {errorMessage.city && (
                            <span className="error">{errorMessage.city}</span>
                          )}

                          <input
                            type="text"
                            placeholder="Pincode"
                            onChange={(e) =>
                              handleChange(e, setpincode, "pincode")
                            }
                            value={pincode}
                            disabled={isSubmitting}
                          />
                          {errorMessage.pincode && (
                            <span className="error">{errorMessage.pincode}</span>
                          )}

                          <input
                            type="text"
                            placeholder="Gst Number"
                            onChange={(e) =>
                              handleChange(e, setgstnumber, "gstnumber")
                            }
                            value={gstnumber}
                            disabled={isSubmitting}
                          />

                          <input
                            type="text"
                            placeholder="State"
                            onChange={(e) => handleChange(e, setstate, "state")}
                            value={state}
                            disabled={isSubmitting}
                          />
                          {errorMessage.state && (
                            <span className="error">{errorMessage.state}</span>
                          )}

                          <div className="button-box">
                            <button
                              className="login-button w-100"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              <span>
                                {isSubmitting ? "Registering..." : "Register"}
                              </span>
                            </button>
                          </div>
                        </form>

                        {errorMessage.register && (
                          <span className="error">
                            {errorMessage.register}
                          </span>
                        )}

                        <form>
                          <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col-md-12">
                              <div className="button-box">
                                <button
                                  className="forget-password-button w-100"
                                  type="button"
                                  onClick={() => navigateTo("/login")}
                                >
                                  <span>Already have an account?</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>

                        {/* Success Modal */}
                        {isModalVisible && (
                          <div className="modal-overlay" style={{position: "fixed",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 999}}>
                            <div className="modal-content" style={{backgroundColor: "white",
  padding: "20px",
  borderRadius: "10px",
  textAlign: "center",
  width: "90%",
  maxWidth: "450px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)"}}>
                              <h5 style={{  marginBottom: "15px",
  fontSize: "20px",
  color: "#333"}}>Registration Successful!</h5>
                              <p style={{  marginBottom: "25px",
  fontSize: "16px",
  color: "#555"}}>
                                Your account has been created. You can now log
                                in.
                              </p>
                              <button
                                className="modal-button"
                                onClick={handleModalClose}
                                style={{  backgroundColor: "#ff7004",
                                  color: "white",
                                  padding: "10px 20px",
                                  border: "none",
                                  borderRadius: "5px",
                                  cursor: "pointer"}}
                              >
                                Go to Login
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>
          </div>
          </div>
          </>
); }
