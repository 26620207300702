import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PostApiHandler } from "../utils/Api";
import { Circles } from "react-loader-spinner";
import CustomToast from "../Components/CustomToast";
import Sidebar from "../Components/Sidebar/Sidebar";
import "../assets/css/orderhistory.css"; // Make sure this file contains styling

export default function OrderDetails() {
  const { id } = useParams(); // Get the order ID from the URL
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  // Retrieve user details from localStorage
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    fetchOrderDetails(id);
  }, [id]);

  // Fetch order details using a POST request
  const fetchOrderDetails = async (orderId) => {
    try {
      const endPoint = `orders/${orderId}`;
      const result = await PostApiHandler({ id: orderId }, endPoint);
      if (result && result.success) {
        setOrderDetails(result.orderItem);
      } else {
        CustomToast(result.message, "error");
      }
    } catch (error) {
      console.error("Error fetching order details:", error.message);
      CustomToast("Failed to load order details", "error");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Format dates
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  return (
    <>
      {loading ? (
        <div className="loading-overlay">
          <Circles
            height="80"
            width="80"
            color="#3498db"
            ariaLabel="loading"
            wrapperStyle={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 1000,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
            }}
          />
        </div>
      ) : (
        <div className="order-details-container">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 col-md-12">
                <Sidebar />
              </div>
              <div className="col-lg-10 col-md-12">
                <div className="order-content">
                  {/* Top Section */}
                  <div className="order-top-section d-flex justify-content-between align-items-center">
                    <div>
                      <h2>Order #{orderDetails.id}</h2>
                      <div className="order-dates">
                        <span>
                          Rental Date & Time :
                          {orderDetails.rentalstartdate ===
                          orderDetails.rentalenddate ? (
                            <>
                              {formatDate(orderDetails.rentalstartdate)} (
                              {orderDetails.time_slot})
                            </>
                          ) : (
                            <>
                              {formatDate(orderDetails.rentalstartdate)} to{" "}
                              {formatDate(orderDetails.rentalenddate)}
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="order-actions d-flex">
                      {/* Order Status Badge */}
                      <div
                        className={`order-status-badge ${orderDetails.order_status?.order_type.toLowerCase()}`}
                      >
                        {orderDetails.order_status?.status || "Unknown"}
                      </div>
                    </div>
                  </div>

                  {/* Rest of the page follows... */}
                  <div className="info-section-wrapper d-flex justify-content-between">
                    <div className="info-section">
                      <h4>Customer & Order</h4>
                      <p>
                        <strong>Name:</strong> {user.name}
                      </p>
                      <p>
                        <strong>Email:</strong> {user.email}
                      </p>
                      <p>
                        <strong>Phone:</strong> {user.mobile}
                      </p>
                      <p>
                        <strong>Company Name:</strong> {user.company_name}
                      </p>
                    </div>

                    <div className="info-section">
                      <h4>Delivery Address</h4>
                      <p>
                        <strong>Address:</strong>{" "}
                        {orderDetails.delivery_address}
                      </p>
                      <p>
                        <strong>Event Venue:</strong> {orderDetails.event_venue}
                      </p>
                    </div>
                  </div>

                  <div className="items-ordered">
                    <h3>Items Ordered</h3>
                    <table className="order-items-table">
                      <thead>
                        <tr>
                          <th>Image</th>
                          <th>Item Name</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderDetails.cart.map((item) => (
                          <tr key={item.id}>
                            <td>
                              <img
                                src={
                                  item.product.image_1 ||
                                  "https://via.placeholder.com/150"
                                }
                                alt={item.product.product_name}
                                style={{ width: "100px", height: "auto" }}
                              />
                            </td>
                            <td>{item.product.product_name}</td>
                            <td>{item.quantity}</td>
                            <td>
                              ₹{parseFloat(item.per_unit_price).toFixed(2)}
                            </td>
                            <td>₹{parseFloat(item.total_amount).toFixed(2)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <div className="order-total">
                      <p>
                        <strong>Grand Total:</strong> ₹
                        {parseFloat(orderDetails.grand_total).toFixed(2)}
                      </p>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
