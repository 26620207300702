import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaUserCircle, FaHistory, FaLock, FaSignOutAlt } from "react-icons/fa"; // Importing icons
import "./Sidebar.css"; // Separate CSS file for Sidebar
import IsToken from "../../utils/Handeler/IsToken";
import CustomToast from "../CustomToast";
import { logoutUser } from "../../utils/Api";

const Sidebar = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user ? user.token : null;
  const navigateTo = useNavigate();
  const location = useLocation(); // To get the current route

  const handleLogout = async () => {
    if (IsToken()) {
      try {
        const data = await logoutUser(token);
        if (data.success) {
          localStorage.removeItem("user");
          sessionStorage.removeItem("user");
          navigateTo("/login");
        } else {
          CustomToast("Logout failed", "error");
        }
      } catch (error) {
        console.error("Logout error:", error);
        CustomToast("An error occurred. Please try again.", "error");
      }
    } else {
      CustomToast("No user is currently logged in.", "warning");
    }
  };

  return (
    <div className="sidebar">
      <ul className="sidebar-menu">
        <li className={location.pathname === "/my-account" ? "active" : ""}>
          <Link to="/my-account">
            <FaUserCircle className="menu-icon" /> <span>My Account</span>
          </Link>
        </li>
        <li className={location.pathname === "/order-history" ? "active" : ""}>
          <Link to="/order-history">
            <FaHistory className="menu-icon" /> <span>Order History</span>
          </Link>
        </li>
        <li className={location.pathname === "/change-password" ? "active" : ""}>
          <Link to="/my-account">
            <FaLock className="menu-icon" /> <span>Change Password</span>
          </Link>
        </li>
        <li>
          <Link to="#" onClick={handleLogout}>
            <FaSignOutAlt className="menu-icon" /> <span>Log Out</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
