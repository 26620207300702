import { useState } from "react";
import { loginUser } from "../utils/Api";
import { useNavigate, useLocation } from "react-router-dom";
import CustomToast from "../Components/CustomToast";

export default function Login() {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState({
    phone: "",
    password: "",
    login: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigateTo = useNavigate();
  const location = useLocation();
  const from = location.state?.from || "/my-account";

  const handleInputChange = (e) => {
    setPhone(e.target.value);
    setErrorMessage((prev) => ({ ...prev, phone: "" }));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrorMessage((prev) => ({ ...prev, password: "" }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (phone === "") {
      setErrorMessage((prev) => ({
        ...prev,
        phone: "This phone field is required.",
      }));
      setIsSubmitting(false);
      return;
    }

    if (password === "") {
      setErrorMessage((prev) => ({
        ...prev,
        password: "This password field is required.",
      }));
      setIsSubmitting(false);
      return;
    }

    setErrorMessage({});

    try {
      const data = await loginUser(phone, password);
      console.log(data);

      if (data.success) {
        localStorage.setItem("user", JSON.stringify(data.status));
        // CustomToast("Login successful","success");
        navigateTo(from, { replace: true }); // Redirect to the previous page after login
      } else {
        setErrorMessage((prev) => ({
          ...prev,
          login: data.message || "Login failed",
        }));
        CustomToast(data.message || "Login failed", "error");
      }
    } catch (error) {
      setErrorMessage((prev) => ({
        ...prev,
        login: "An error occurred. Please try again.",
      }));
      CustomToast("An error occurred. Please try again.", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const user = JSON.parse(localStorage.getItem("user"));
  // const userType = user ? user.usertype : null;
  // const token = user ? user.token : null;
  // const name = user ? user.name : null;
  // const mobile = user ? user.mobile : null;
  // const email = user ? user.email : null;

  //   console.log("User Type:", userType);
  //   console.log("Token:", token);
  //   console.log("Name:", name);
  //   console.log("Phone:", mobile);

  return (
    <>
      <div className="login-register-area pt-100px pb-100px">
        <div className="container">
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6 col-md-12 ml-auto mr-auto">
              <div className="login-register-wrapper">
                <div className="login-register-tab-list nav">
                  <a className="active" data-bs-toggle="tab" href="#lg1">
                    <h4>Login</h4>
                  </a>
                </div>
                <div className="tab-content">
                  <div id="lg1" className="tab-pane active">
                    <div className="login-form-container">
                      <div className="login-register-form">
                        <form onSubmit={handleFormSubmit}>
                          <input
                            type="text"
                            placeholder="Mobile"
                            onChange={handleInputChange}
                            value={phone}
                            disabled={isSubmitting}
                          />
                          {errorMessage.phone && (
                            <span className="error">{errorMessage.phone}</span>
                          )}
                          <input
                            type="password"
                            placeholder="Password"
                            onChange={handlePasswordChange}
                            value={password}
                            disabled={isSubmitting}
                          />
                          {errorMessage.password && (
                            <span className="error">
                              {errorMessage.password}
                            </span>
                          )}

                          <div className="button-box">
                            <button
                              className="login-button w-100"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              <span>
                                {isSubmitting ? "Logging in..." : "Login"}
                              </span>
                            </button>
                          </div>
                        </form>
                        {errorMessage.login && (
                          <span className="error">{errorMessage.login}</span>
                        )}

                        <div className="row" style={{ marginTop: "20px" }}>
                          <div className="col-md-6">
                            <div className="button-box">
                              <button
                                className="create-account-button w-100"
                                type="button"
                                onClick={() => navigateTo("/register")}
                              >
                                <span>Create an account</span>
                              </button>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="button-box">
                              <button
                                className="forget-password-button w-100"
                                type="button"
                              >
                                <span>Forget Password?</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>
        </div>
      </div>
    </>
  );
}
