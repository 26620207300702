import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GetOrdersApiHandler, PostApiHandler } from "../utils/Api";
import IsToken from "../utils/Handeler/IsToken";
import CustomToast from "../Components/CustomToast";
import { toast } from "react-toastify";
import { Circles } from "react-loader-spinner";
import { Tooltip as ReactTooltip } from "react-tooltip";

import "../assets/css/orderhistory.css";
import Sidebar from "../Components/Sidebar/Sidebar";

export default function Orderhistory() {
  const navigateTo = useNavigate();

  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");
  const [orderIdSearch, setOrderIdSearch] = useState("");
  const limit = 10;

  const user = JSON.parse(localStorage.getItem("user"));
  const token = user ? user.token : null;

  useEffect(() => {
    if (!IsToken()) {
      navigateTo("/login");
    } else {
      fetchOrderHistory(currentPage, orderStatus, orderIdSearch);
    }
  }, [currentPage, orderStatus]);

  const fetchOrderHistory = async (page, status, orderId) => {
    try {
      setLoading(true);
      let endPoint = `orders?limit=${limit}&page=${page}`;
      if (status) {
        endPoint += `&order_status=${status}`;
      }
      if (orderId) {
        endPoint += `&order_id=${orderId}`;
      }

      const result = await GetOrdersApiHandler({}, endPoint);
      if (result && result.success) {
        setOrders(result.orders.data);
        setTotalPages(result.orders.last_page || 1);
      } else {
        CustomToast("Failed to load orders", "error");
      }
    } catch (err) {
      CustomToast("Error fetching order history", "error");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleStatusChange = (status) => {
    setOrderStatus(status);
  };

  const handleOrderIdSearch = () => {
    fetchOrderHistory(currentPage, orderStatus, orderIdSearch);
  };

  const confirmCancelOrder = (orderId) => {
    toast(
      ({ closeToast }) => (
        <div>
          <p>Do you want to Cancel This Order?</p>
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-success btn-sm"
              onClick={() => {
                handleCancelOrder(orderId);
                closeToast();
              }}
            >
              Yes
            </button>
            <button className="btn btn-danger btn-sm" onClick={closeToast}>
              No
            </button>
          </div>
        </div>
      ),
      {
        autoClose: false,
        closeOnClick: false,
        position: "top-center",
      }
    );
  };

  const handleCancelOrder = async (orderId) => {
    try {
      const result = await PostApiHandler({}, `orders/cancel-order/${orderId}`);
      if (result.success) {
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.id === orderId
              ? { ...order, order_status: { status: "cancelled" } }
              : order
          )
        );
        CustomToast("Order cancelled successfully.", "success");
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      CustomToast("Failed to cancel the order.", "error");
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  const handleReset = () => {
    setOrderStatus("");
    setOrderIdSearch("");
    fetchOrderHistory(1, "", "");
  };

  return (
    <>
      <div className="order-history-wrapper">
        {/* Using Sidebar Component */}
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-12">
              <Sidebar />
            </div>
            <div className="col-lg-10 col-md-12">
              <div className="order-content">
                <div className="order-header">
                  <div className="order-tabs">
                    <button
                      className={orderStatus === "" ? "active" : ""}
                      onClick={() => handleStatusChange("")}
                    >
                      All Orders
                    </button>
                    <button
                      className={orderStatus === "1" ? "active" : ""}
                      onClick={() => handleStatusChange("1")}
                    >
                      New Order
                    </button>
                    <button
                      className={orderStatus === "2" ? "active" : ""}
                      onClick={() => handleStatusChange("2")}
                    >
                      Processing
                    </button>
                    <button
                      className={orderStatus === "3" ? "active" : ""}
                      onClick={() => handleStatusChange("3")}
                    >
                      Delivered
                    </button>
                    <button
                      className={orderStatus === "4" ? "active" : ""}
                      onClick={() => handleStatusChange("4")}
                    >
                      Cancelled
                    </button>
                  </div>

                  <div className="search-bar">
                    <input
                      type="text"
                      placeholder="Search Order ID"
                      value={orderIdSearch}
                      onChange={(e) => setOrderIdSearch(e.target.value)}
                    />
                    <button
                      onClick={handleOrderIdSearch}
                      className="searchbutton"
                    >
                      Search
                    </button>
                    <button onClick={handleReset} className="resetbutton">
                      Reset
                    </button>
                  </div>
                </div>

                <div className="order-table">
                  {orders.length > 0 ? (
                    <table className="tableorderhistory">
                      <thead>
                        <tr>
                          <th>Order ID</th>
                          <th>Rental Date & Time</th>

                          <th>Grand Total</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orders.map((order) => (
                          <tr key={order.id}>
                            <td>#{order.id}</td>
                            <td>
                              {order.rentalstartdate === order.rentalenddate ? (
                                <>
                                  {formatDate(order.rentalstartdate)} (
                                  {order.time_slot})
                                </>
                              ) : (
                                <>
                                  {formatDate(order.rentalstartdate)} to{" "}
                                  {formatDate(order.rentalenddate)}
                                </>
                              )}
                            </td>

                            <td>
                              ₹
                              {parseFloat(order.grand_total)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td>
                              <span
                                className={`status ${order.order_status.status
                                  .toLowerCase()
                                  .replace(" ", "_")}`}
                              >
                                {order.order_status.status}
                              </span>
                            </td>
                            <td>
                              {order.order_status.order_type ===
                                "new_order" && (
                                <i
                                  className="ion-android-cancel cancel-icon"
                                  onClick={() => confirmCancelOrder(order.id)}
                                  data-tooltip-id="cancel-tooltip"
                                  data-tooltip-content="Cancel Order"
                                />
                              )}
                              <i
                                className="ion-eye view-order-icon"
                                onClick={() =>
                                  navigateTo(`/order-details/${order.id}`)
                                }
                                data-tooltip-id="view-tooltip"
                                data-tooltip-content="View Order"
                              />
                              <ReactTooltip
                                id="cancel-tooltip"
                                place="top"
                                effect="solid"
                              />
                              <ReactTooltip
                                id="view-tooltip"
                                place="top"
                                effect="solid"
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>No orders found.</p>
                  )}
                </div>

                <div className="pagination">
                  <button
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Previous
                  </button>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <button
                      key={index + 1}
                      className={currentPage === index + 1 ? "active" : ""}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  ))}
                  <button
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
