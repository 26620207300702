import React, { useState, useEffect } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { GetApiHandlerWithoutParams, PostApiHandler } from "../utils/Api";

export default function Checkout() {
  const user = JSON.parse(localStorage.getItem("user"));
  const UserTypeTier = user ? user.user_type_tier : null;
  const DiscountPercentage = user ? user.discount_percentage || 0 : 0;
  // const GST_RATE = user ? user.gst_percentage || 0:0;

  const [products, setProducts] = useState([]);
  const { fetchCartItems } = useOutletContext();
  const [selectedPincode, setSelectedPincode] = useState("");
  const [locationAndDates, setLocationAndDates] = useState(() => {
    const saved = localStorage.getItem("locationDateFilter");
    return saved ? JSON.parse(saved) : {};
  });
  const [timeSlot, setTimeSlot] = useState(locationAndDates?.timeSlot || ""); // Capture time slot
  const [startDate, setStartDate] = useState(locationAndDates?.startDate || "");
  const [endDate, setEndDate] = useState(locationAndDates?.endDate || "");
  const [locationid, setLocationid] = useState(locationAndDates?.locationId || "");
  const [eventvenue, setEventvenue] = useState("");
  const [eventtype, setEventtype] = useState("");
  const [deliveryaddress, setDeliveryaddress] = useState("");
  const [additionalrequest, setAdditionalrequest] = useState("");
  const [pincodeMessage, setPincodeMessage] = useState("");
  const [pincodeValid, setPincodeValid] = useState(null); // State for pincode validity
  const [errorMessage, setErrorMessage] = useState({
    eventvenue: "",
    eventtype: "",
    deliveryaddress: "",
    pincode: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  // Format dates
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  // Calculate return date (next day after end date)
  const getReturnDate = (endDate) => {
    const date = new Date(endDate);
    date.setDate(date.getDate() + 1);
    return date.toISOString().split("T")[0]; // YYYY-MM-DD format
  };

  // Map time slot to API format
  const mapTimeSlot = (timeSlot) => {
    switch (timeSlot) {
      case "morning":
        return "Morning 10 AM - 4 PM";
      case "evening":
        return "Evening 4 PM - Midnight";
      case "fullday":
        return "Full Day";
      default:
        return "";
    }
  };

  // Function to calculate the subtotal for each item
  const calculateSubtotal = (price, quantity) => {
    return (price * quantity).toFixed(2);
  };

  // Function to calculate the grand total before discount
  const calculateGrandTotalBeforeDiscount = () => {
    return parseFloat(
      products.reduce(
        (acc, item) => acc + parseFloat(calculateSubtotal(item.price, item.quantity)),
        0
      )
    );
  };

  // Function to calculate GST
  // const calculateGST = (total) => {
  //   return (total * GST_RATE) / 100;
  // };

  // Function to calculate the grand total after applying discount
  const calculateGrandTotal = () => {
    const grandTotalBeforeDiscount = calculateGrandTotalBeforeDiscount();
    const discount = (grandTotalBeforeDiscount * DiscountPercentage) / 100; // Calculate discount
    const totalAfterDiscount = grandTotalBeforeDiscount - discount;
    // const gstAmount = calculateGST(totalAfterDiscount); // Calculate GST
    return (totalAfterDiscount).toFixed(2); // Return the total including GST
  };

  const checkPincode = async () => {
    if (!selectedPincode) {
      setPincodeMessage("Please enter a pincode");
      setPincodeValid(false);
      return;
    }

    try {
      const endPoint = `check-pincode?pincode=${selectedPincode}`;
      const result = await GetApiHandlerWithoutParams(endPoint, "");
      if (result && result.status) {
        setPincodeValid(true); // Pincode is valid, enable "Place Order" button
        setPincodeMessage(result.message); // Show success message
      } else {
        setPincodeValid(false); // Pincode is not valid, disable "Place Order" button
        setPincodeMessage(result.message || "Pincode is not serviceable");
      }
    } catch (err) {
      setPincodeValid(false);
      setPincodeMessage("Failed to check pincode. Please try again.");
      console.error("Pincode check failed:", err.message);
    }
  };

  // Fetch Cart Data API
  useEffect(() => {
    GetCartItemsApiCall();
  }, []);

  const GetCartItemsApiCall = async () => {
    try {
      const endPoint = `carts`;
      const result = await GetApiHandlerWithoutParams(endPoint, "");
      if (result && result.success) {
        const fetchedProducts = result.cart.map((cart) => ({
          id: cart.id,
          name: cart.product.product_name,
          price: cart.product.rental_price,
          totalAmount: cart.total_amount,
          quantity: cart.quantity,
        }));
        setProducts(fetchedProducts);
      }
    } catch (err) {
      console.error("Failed to fetch Cart items:", err.message);
    }
  };

  // Handle form input changes
  const handleChange = (e, setStateFunc, field) => {
    setStateFunc(e.target.value);
    setErrorMessage((prev) => ({ ...prev, [field]: "" })); // Clear error message for the field
  };

  // Clear the cart after order placement
  const handleClearCart = async () => {
    try {
      const result = await PostApiHandler({}, `carts/delete-all`);
      if (result.success) {
        setProducts([]); // Clear the products list on success
        fetchCartItems(); // Fetch the updated cart count for the header
      } else {
        console.error("Failed to clear cart:", result.message);
      }
    } catch (error) {
      console.error("Failed to clear cart:", error.message);
    }
  };

  // Place Order API Call
  const placeOrderApiCall = async () => {
    // Validate fields before making the API call
    if (!eventvenue || !eventtype || !deliveryaddress || !selectedPincode) {
      setErrorMessage({
        eventvenue: eventvenue ? "" : "Event Venue is required.",
        eventtype: eventtype ? "" : "Occasion is required.",
        deliveryaddress: deliveryaddress ? "" : "Delivery Address is required.",
        pincode: selectedPincode ? "" : "Pincode is required.",
      });
      return;
    }

    const params = {
      event_venue: eventvenue,
      location_id: locationid,
      rentalstartdate: startDate,
      rentalenddate: endDate,
      returndate: getReturnDate(endDate),
      event_type: eventtype,
      delivery_address: deliveryaddress,
      pincode: selectedPincode,
      grand_total: calculateGrandTotal(), // Use grand total after discount
      additional_request: additionalrequest,
      time_slot: mapTimeSlot(timeSlot), // Map the time slot to the required format
    };

    setIsSubmitting(true);

    try {
      const endPoint = `place-order`;
      const result = await PostApiHandler(params, endPoint);
      if (result.success) {
        navigate("/order-success", { state: { orderData: result.orders } });
        await handleClearCart();
        fetchCartItems();
      } else {
        alert(result.message);
      }
    } catch (error) {
      alert("Failed to place order. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="checkout-area pt-100px pb-100px">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="billing-info-wrap">
                <h3>Event Details</h3>
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="billing-info mb-20px">
                      <label>Event Venue</label>
                      <input
                        type="text"
                        value={eventvenue}
                        onChange={(e) =>
                          handleChange(e, setEventvenue, "eventvenue")
                        }
                        disabled={isSubmitting}
                      />
                      {errorMessage.eventvenue && (
                        <span className="error">{errorMessage.eventvenue}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="billing-info mb-20px">
                      <label>Occasion</label>
                      <input
                        type="text"
                        value={eventtype}
                        onChange={(e) =>
                          handleChange(e, setEventtype, "eventtype")
                        }
                        disabled={isSubmitting}
                      />
                      {errorMessage.eventtype && (
                        <span className="error">{errorMessage.eventtype}</span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="billing-info mb-20px">
                      <label>Event Date & Time</label>
                      <input
                        className="billing-address"
                        type="text"
                        value={
                          startDate === endDate
                            ? `${formatDate(startDate)} (${mapTimeSlot(timeSlot)})` // Wrapped time slot in parentheses
                            : `${formatDate(startDate)} to ${formatDate(endDate)}`
                        }
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6">
                    <div className="billing-select mb-20px">
                      <label>Pincode</label>
                      <div className="pincode-input-container">
                        <input
                          type="text"
                          value={selectedPincode}
                          onChange={(e) => setSelectedPincode(e.target.value)}
                          disabled={isSubmitting}
                          className="pincode-input"
                        />
                        <button
                          className="check-pincode-btn"
                          onClick={checkPincode}
                          disabled={isSubmitting}
                        >
                          Check
                        </button>
                      </div>
                      {pincodeMessage && (
                        <span
                          className={
                            pincodeValid ? "success-message" : "error-message"
                          }
                        >
                          {pincodeMessage}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="billing-info mb-20px">
                      <label>Delivery Address</label>
                      <input
                        className="billing-address"
                        type="text"
                        value={deliveryaddress}
                        onChange={(e) =>
                          handleChange(e, setDeliveryaddress, "deliveryaddress")
                        }
                        disabled={isSubmitting}
                      />
                      {errorMessage.deliveryaddress && (
                        <span className="error">
                          {errorMessage.deliveryaddress}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="additional-info-wrap">
                  <h4>Additional Request / Requirements</h4>
                  <div className="additional-info">
                    <textarea
                      placeholder="Please add any additional request or requirements... "
                      value={additionalrequest}
                      onChange={(e) =>
                        handleChange(e, setAdditionalrequest, "additionalrequest")
                      }
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5 mt-md-30px mt-lm-30px">
              <div className="your-order-area">
                <h3>Your order</h3>
                <div className="your-order-wrap gray-bg-4">
                  <div className="your-order-product-info">
                    <div className="your-order-top">
                      <ul>
                        <li>Product</li>
                        <li>Total</li>
                      </ul>
                    </div>
                    <div className="your-order-middle">
                      <ul>
                        {products.map((item) => (
                          <li key={item.id}>
                            <span className="order-middle-left">
                              {item.name} X {item.quantity}
                            </span>{" "}
                            <span className="order-price">
                              ₹
                              {parseFloat(item.totalAmount)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="your-order-total">
                      <ul>
                        <li className="order-total">Total</li>
                        <li>
                          ₹
                          {parseFloat(calculateGrandTotalBeforeDiscount())
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </li>
                      </ul>

                      <ul>
                        <li className="order-total">Discount</li>
                        <li>{DiscountPercentage}%</li>
                      </ul>

                      {/* <ul>
                        <li className="order-total">GST ({GST_RATE}%)</li>
                        <li>
                          ₹
                          {parseFloat(calculateGST(calculateGrandTotalBeforeDiscount()))
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </li>
                      </ul> */}

                      <ul>
                        <li className="order-total">Grand Total</li>
                        <li>
                          ₹
                          {parseFloat(calculateGrandTotal())
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="Place-order mt-25">
                  <Link
                    className={`btn-hover ${!pincodeValid ? "disabled" : ""}`}
                    onClick={pincodeValid ? placeOrderApiCall : null}
                  >
                    Place Order
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
